import {
  InstrumentAction,
  InstrumentEvent,
  InstrumentStatus,
  InstrumentTagType,
  InstrumentUploaderType,
} from '@finverity/graphql';
import { FinButtonAppearance } from '@finverity/ui-kit';

import { InstrumentApprovalCheckerSubscriptionEvents, InstrumentApprovalConfigs } from '../../../types';
import { InstrumentApprovalCheckerDataGridColId } from '../../instrument-approval-checker-container/constants';

export enum FunderInstrumentApprovalCheckerFilterIdentifier {
  PendingFunderApprovalChecker = 'PENDING_FUNDER_APPROVAL_CHECKER',
  NotFundable = 'NOT_FUNDABLE',
}

export const FUNDER_INSTRUMENT_APPROVAL_CHECKER_CONFIGS: InstrumentApprovalConfigs = {
  companyType: InstrumentUploaderType.DealManagerFunder,
  pageTitle: 'Funder Approval (Checker)',
  actionsDefs: [
    {
      buttonText: 'Review & Approve',
      buttonSvgIconName: 'fvIconInstrumentApprovalReview',
      buttonAppearance: FinButtonAppearance.Primary,
      actionType: InstrumentAction.FunderApprovalCheckerApprove,
      disabled: true,
      disabledTooltip: null,
    },
    {
      buttonText: 'Reject',
      buttonSvgIconName: 'fvIconInstrumentApprovalReject',
      buttonAppearance: FinButtonAppearance.OutlineAccent,
      actionType: InstrumentAction.FunderApprovalCheckerReject,
      disabled: true,
    },
  ],
  filtersDefs: [
    {
      identifier: FunderInstrumentApprovalCheckerFilterIdentifier.PendingFunderApprovalChecker,
      label: 'Pending Funder Approval (Checker)',
      params: {
        statuses: [InstrumentStatus.FunderApprovalPendingCheckerConfirmation],
        eventType: InstrumentEvent.FunderApprovalMakerApproved,
        tags: {
          notIncludes: [
            InstrumentTagType.Deleted,
            InstrumentTagType.NotFundable,
            InstrumentTagType.NoEligibleFundingWindow,
          ],
        },
      },
      count: 0,
      hiddenColumns: [],
    },
    {
      identifier: FunderInstrumentApprovalCheckerFilterIdentifier.NotFundable,
      label: 'Not Fundable',
      params: {
        statuses: [InstrumentStatus.FunderApprovalPendingCheckerConfirmation],
        eventType: InstrumentEvent.FunderApprovalMakerApproved,
        tags: {
          notIncludes: [InstrumentTagType.Deleted],
          includes: [InstrumentTagType.NotFundable, InstrumentTagType.NoEligibleFundingWindow],
        },
      },
      count: 0,
      hiddenColumns: [
        InstrumentApprovalCheckerDataGridColId.DisbursementDate,
        InstrumentApprovalCheckerDataGridColId.Tenor,
        InstrumentApprovalCheckerDataGridColId.RepaymentDate,
        InstrumentApprovalCheckerDataGridColId.AdvanceRate,
        InstrumentApprovalCheckerDataGridColId.PrincipalAmount,
        InstrumentApprovalCheckerDataGridColId.DisbursementAmount,
        InstrumentApprovalCheckerDataGridColId.RepaymentAmount,
        InstrumentApprovalCheckerDataGridColId.NetUnfinancedBalance,
        InstrumentApprovalCheckerDataGridColId.SellerFeesInAdvance,
        InstrumentApprovalCheckerDataGridColId.PayerFeesInAdvance,
        InstrumentApprovalCheckerDataGridColId.SellerFeesInArrears,
        InstrumentApprovalCheckerDataGridColId.PayerFeesInArrears,
        InstrumentApprovalCheckerDataGridColId.TotalSellerFees,
        InstrumentApprovalCheckerDataGridColId.TotalPayerFees,
        InstrumentApprovalCheckerDataGridColId.TotalFees,
      ],
    },
  ],
  subscriptionEvents: <InstrumentApprovalCheckerSubscriptionEvents>{
    approvedSuccess: [InstrumentEvent.FundingPending],
    approvedFailure: [InstrumentEvent.FunderApprovalCheckerPending],
    rejected: [InstrumentEvent.FunderApprovalCheckerRejected],
    removed: [InstrumentEvent.InstrumentDeleted],
    updated: [InstrumentEvent.InstrumentEdited],
  },
};
